import React from "react";
import { connect } from "react-redux";

import { AppState } from "shared/data/reducers";

import { changeLanguage } from "./SetLanguage";

type Props = {
  supportedLanguages: any;
  currentLanguage: string | null;
};

function SetLanguage({ supportedLanguages }: Props) {
  return supportedLanguages.map((supportedLanguage) => (
    <a
      key={supportedLanguage.Language}
      href="#"
      className="header-mobile-menu-link"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        changeLanguage(supportedLanguage.Language);
      }}
    >
      {supportedLanguage.Language.toUpperCase()}
    </a>
  ));
}

export default connect((state: AppState) => ({
  supportedLanguages: state.requestorConfig.supportedLanguages,
  currentLanguage: state.requestorConfig.currentLanguage,
}))(SetLanguage);
