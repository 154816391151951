import classnames from "classnames";
import moment from "moment";
import Router, { SingletonRouter, withRouter } from "next/router";
import React, { Component } from "react";
import { IntlShape, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { getCbtToken } from "shared/cbt/cbt";
import { MODULES } from "shared/constants";
import { initPassengerForm } from "shared/data/actions/cbt";
import { getDestinationByCode } from "shared/data/actions/destinations";
import { setSearchQuery } from "shared/data/actions/storage";
import { AppState } from "shared/data/reducers";
import {
  AirportDestinations,
  DefaultAirportSuggestions,
  SearchQuery,
} from "shared/data/reducers/types/storage";
import { searchFlightBase } from "shared/lib/constants";
import {
  countPassengers,
  getBasicDate,
  isEmptyObject,
  pad2,
} from "shared/lib/functions";
import { PassengersCount } from "shared/lib/golObjectTypes/PassengerTypes";
import {
  Airlines,
  AirportSelectValue,
} from "shared/lib/golObjectTypes/SelectObjects";
import {
  maxPlusMinusDays,
  plusMinusDaysEnabled,
} from "shared/lib/requestorFunctions";
import { AirportDestination, FrontendSettings } from "shared/types";

import Img from "@components/FileServer/Img";
import SearchFormAirportSelect from "@components/SearchForm/AirportSelect";
import DatePicker from "@components/SearchForm/Calendar/DatePicker";
import SearchFormHotelSelect from "@components/SearchForm/HotelSelect";
import PassengerPreferences, {
  cbtFormFilled,
} from "@components/SearchForm/PassengerPreferences";
import FormattedMessage from "@components/UI/FormattedMessage";

import { checkIsHotelSearchForm } from "@lib/webFunctions";
import { ROUTES } from "@shared/constants";

import DatesRow from "./DatesRow";
import FilterBar from "./FilterBar/FilterBar";
import FilterBarMobile from "./FilterBar/FilterBarMobile";

interface HeaderSearchFormProps {
  frontendSettings: FrontendSettings;
  query?: SearchQuery;
  page?: string;
  intl?: IntlShape;
  defaultAirportSuggestions?: DefaultAirportSuggestions;
  dispatch: Dispatch<any>;
  destinations: AirportDestinations;
  departureDate?: string;
  departureDays?: number;
  durationOfStay?: number;
  currentLanguage?: string;
  router?: SingletonRouter;
  airlines?: Airlines;
  toleranceDays?: number;
  hotelsEnabled: boolean;
  anonymousSearchEnabled: boolean;
  searchQuery: SearchQuery;
  customerUsername?: string;
  carrierFilter: string[] | null;
  module: string[];
}

interface SearchFlight {
  origin: string;
  destination: string;
  departureDate: string;
}

interface HeaderSearchFormState {
  typeSearch: string;
  toleranceDays: number;
  // eslint-disable-next-line camelcase
  max_transfers?: string;
  directFlight: boolean;
  selectedTransportCompany: string;
  weekends: boolean;
  from: AirportSelectValue;
  to: string | AirportSelectValue;
  departureDate: string;
  returnDate: string;
  searchFlights: SearchFlight[];
  passengers: PassengersCount;
  airlinesTouched: boolean;
  flightClass: string;
  error: {
    to: boolean;
    from: boolean;
    multiCity: Object;
    cbtPassengersForm: boolean;
  };
  airlines?: Airlines;
  rooms: number;
  cbtPassengersForm?: any;
}

const EXPORT_HTML_PACKAGE = Boolean(process.env.NEXT_PUBLIC_EXPORT_PACKAGE);

class HeaderSearchForm extends Component<
  HeaderSearchFormProps,
  HeaderSearchFormState
> {
  state: HeaderSearchFormState = {
    typeSearch: "RETURN",
    toleranceDays: this.props.toleranceDays || 0,
    directFlight: false,
    selectedTransportCompany: "all",
    weekends: false,
    from: this.props.defaultAirportSuggestions[0]
      ? {
          value: this.props.defaultAirportSuggestions[0].Code,
          label: this.props.defaultAirportSuggestions[0].label,
          Country: this.props.defaultAirportSuggestions[0].Country,
        }
      : "",
    to: searchFlightBase.destination,
    departureDate: getBasicDate(this.props.departureDays),
    returnDate: getBasicDate(
      this.props.departureDays + this.props.durationOfStay
    ),
    searchFlights: [
      {
        ...searchFlightBase,
        departureDate: getBasicDate(this.props.departureDays),
      },
      {
        ...searchFlightBase,
        departureDate: getBasicDate(
          this.props.departureDays + this.props.durationOfStay
        ),
      },
    ],
    passengers: getInitialPassengersNumbersFromAllowedTravelerTypes(
      this.props.allowedTravelerTypes
    ),
    rooms: 1,
    airlinesTouched: false,
    flightClass: "ECO",
    error: { to: false, from: false, multiCity: {}, cbtPassengersForm: false },
  };

  componentDidUpdate = (prevProps) => {
    this.updateDirectFlight(prevProps);
    if (this.props.router.query !== prevProps.router.query) {
      this.initCbtPassengerForm(this.props.router.query);
    }
  };

  componentDidMount = async () => {
    const { dispatch, searchQuery, router } = this.props;
    const { query } = router;

    const hotelModuleEnabled = this.props.module?.includes(MODULES.HOTEL);
    const flightsModuleEnabled = this.props.module?.includes(MODULES.AIR);

    if (!flightsModuleEnabled && hotelModuleEnabled) {
      this.changeTypeSearch("HOTELS");
    }

    if (isEmptyObject(searchQuery)) dispatch(setSearchQuery(query));
    this.parseQueryIntoSearchForm(query);
  };

  cannotPrefill = () =>
    ![ROUTES.RESULTS, ROUTES.RESULT, ROUTES.HOTELS].includes(
      this.props.router.route
    );

  updateDirectFlight = (prevProps) => {
    if (this.cannotPrefill()) return;

    if (
      prevProps.router.query.max_transfers !== "direct" &&
      this.props.router.query.max_transfers === "direct"
    ) {
      this.setState({ directFlight: true });
    }
  };

  findDestinationByCode = async (code: string) => {
    return this.props.dispatch(
      getDestinationByCode(code, this.props.destinations)
    );
  };

  defaultValues = async (query: any) => {
    let forUpdate = {};
    if (query.from) {
      const from = { value: query.from, label: "" };
      if (query.from.includes("/")) {
        const airportCodes = query.from.split("/");
        let label = "";
        (
          await Promise.all(airportCodes.map(this.findDestinationByCode))
        ).forEach((res: AirportDestination) => {
          from.Country = res.Country;
          label += ` / ${res.$t}`;
        });
        from.label = label.slice(3);
      } else {
        const label = await this.findDestinationByCode(query.from);
        from.Country = label.Country;
        from.label = label.$t;
      }
      const departureDate = Date.parse(query.departureDate)
        ? query.departureDate
        : this.state.departureDate;
      forUpdate = { ...forUpdate, from, departureDate };
    }
    if (query.to) {
      const label = await this.findDestinationByCode(query.to);

      const returnDate = Date.parse(query.returnDate)
        ? query.returnDate
        : this.state.returnDate;
      if (label) {
        const to = { value: query.to, label: label.$t, Country: label.Country };
        forUpdate = { ...forUpdate, to, returnDate };
      }
    }
    if (query.toleranceDays) {
      forUpdate = { ...forUpdate, toleranceDays: Number(query.toleranceDays) };
    }

    if (query.typeSearch === "hotels") {
      const label = await this.findDestinationByCode(query.destination);
      if (label) {
        const destination = {
          value: query.destination,
          label: label.$t,
          Country: label.Country,
        };
        forUpdate = { ...forUpdate, to: destination };
      }
    }

    this.setState(forUpdate);
  };

  initCbtPassengerForm = (query) => {
    const { costCenterId, travelReasonId } = query;

    if (costCenterId && travelReasonId) {
      this.props.dispatch(initPassengerForm(query));
    }
  };

  parseQueryIntoSearchForm = async (query) => {
    this.initCbtPassengerForm(query);

    if (query === undefined || this.cannotPrefill()) {
      return;
    }

    const queryKeys = Object.keys(query).filter((param) =>
      param.includes("flight_")
    );
    let flightType;
    if (queryKeys.length > 0) {
      flightType = "MULTIPLE";
      const originAirports = [];
      const destinationAirports = [];
      const departureDates = [];
      queryKeys.forEach((el) => {
        if (el.includes("origin")) {
          originAirports.push(query[el]);
        } else if (el.includes("destination")) {
          destinationAirports.push(query[el]);
        } else if (el.includes("departureDate")) {
          departureDates.push(query[el]);
        } else {
          return false;
        }
      });
      await this.parseMultiCityFlight(
        originAirports,
        destinationAirports,
        departureDates
      );
    } else if (!query.returnDate) {
      flightType = "ONE_WAY";
      await this.defaultValues(query);
    } else {
      flightType = "RETURN";
      await this.defaultValues(query);
    }

    if (query.preferred_airline) {
      const airline = this.props.airlines.find(
        (al) => al.value === query.preferred_airline
      );
      if (airline) {
        this.selectAirline({ value: query.preferred_airline });
      }
    }

    this.setState({
      typeSearch: flightType,
      ...(query.max_transfers === "direct" && { directFlight: true }),
      ...this.getPassengers(query),
      ...(query.flightClass ? { flightClass: query.flightClass } : {}),
    });
  };

  getPassengers = (query) => {
    const passengers = {};

    this.props.allowedTravelerTypes.forEach((allowedTravelerType) => {
      passengers[allowedTravelerType.Code] = query[allowedTravelerType.Code]
        ? Number(query[allowedTravelerType.Code])
        : 0;
    });

    return {
      passengers,
      returnDate:
        query.returnDate ||
        query.flight_2_departureDate ||
        getBasicDate(this.props.departureDays + this.props.durationOfStay),
      departureDate:
        query.departureDate ||
        query.flight_1_departureDate ||
        getBasicDate(this.props.departureDays),
    };
  };

  parseMultiCityFlight = async (
    originAirports,
    destinationAirports,
    departureDates
  ) => {
    const searchFlights = [...this.state.searchFlights];

    (await Promise.all(originAirports.map(this.parseMulti))).forEach(
      (airportObj, index) => {
        searchFlights[index] = {
          ...searchFlights[index],
          origin: { ...airportObj },
          departureDate: departureDates[index],
        };

        this.setState({ searchFlights });
      }
    );

    (await Promise.all(destinationAirports.map(this.parseMulti))).forEach(
      (airportObj, index) => {
        searchFlights[index] = {
          ...searchFlights[index],
          destination: { ...airportObj },
        };

        this.setState({ searchFlights });
      }
    );
  };

  parseMulti = async (airport) => {
    const airportObj = {
      value: "",
      label: "",
      Parent: "",
      Code: "",
    };
    if (airport.includes("/")) {
      const airportCodes = airport.split("/");
      let combinedLabel = "";

      (await Promise.all(airportCodes.map(this.findDestinationByCode))).forEach(
        (res: AirportDestination) => {
          combinedLabel += ` / ${res.$t}`;
        }
      );
      airportObj.label = combinedLabel.slice(3);
    } else {
      const res = await this.findDestinationByCode(airport);
      airportObj.Parent = res?.Parent;
      airportObj.label = res?.$t;
    }

    airportObj.value = airport;
    airportObj.Code = airport;

    return airportObj;
  };

  changeTypeSearch = (typeSearch) => {
    if (["HOTELS", "FLIGHTS"].includes(typeSearch)) {
      let futureAsPath;

      if (this.props.router.pathname.includes("[")) {
        if (this.props.router.asPath.includes("?")) {
          if (this.props.router.asPath.includes("typeSearch=")) {
            futureAsPath = this.props.router.asPath
              .split(
                `typeSearch=${this.props.router.query.typeSearch.toLowerCase()}`
              )
              .join(`typeSearch=${typeSearch.toLowerCase()}`);
          } else {
            futureAsPath = `${
              this.props.router.asPath
            }&typeSearch=${typeSearch.toLowerCase()}`;
          }
        } else {
          futureAsPath = `${
            this.props.router.asPath
          }?typeSearch=${typeSearch.toLowerCase()}`;
        }
      }

      return Router.push(
        {
          pathname: this.props.router.pathname,

          query: {
            ...Router.router.query,
            typeSearch: typeSearch === "HOTELS" ? "hotels" : "flights",
          },
        },
        futureAsPath
      );
    }

    if (this.state.typeSearch === "MULTIPLE") {
      this.setState({
        typeSearch,
        from: this.state.searchFlights[0].origin,
        to: this.state.searchFlights[0].destination,
      });
    } else if (typeSearch === "MULTIPLE") {
      const searchFlights = [...this.state.searchFlights];
      searchFlights[0] = {
        ...this.state.searchFlights[0],
        origin: this.state.from,
        destination: this.state.to,
      };
      this.setState({
        typeSearch,
        searchFlights,
      });
    } else {
      this.setState({ typeSearch });
    }
  };

  increaseTolerance = () => {
    if (this.state.weekends) {
      return;
    }

    if (
      this.state.toleranceDays < maxPlusMinusDays(this.props.frontendSettings)
    ) {
      this.setState((state) => ({ toleranceDays: state.toleranceDays + 1 }));
    }
  };

  decreaseTolerance = () => {
    if (this.state.weekends) {
      return;
    }
    if (this.state.toleranceDays > 0) {
      this.setState((state) => ({ toleranceDays: state.toleranceDays - 1 }));
    }
  };

  formatPassengersForQuery = () => {
    return Object.keys(this.state.passengers).reduce((acc, passenger) => {
      if (this.state.passengers[passenger] !== 0) {
        acc[passenger] = this.state.passengers[passenger];
      }
      return acc;
    }, {});
  };

  areInputsFilled = async (query) => {
    const {
      user,
      frontendSettings,
      cbtPassengersForm,
      router,
      customerUsername,
      cbt: { corporate },
    } = this.props;

    const isHotelSearchForm = checkIsHotelSearchForm(router);
    const error = { ...this.state.error };
    const allowGuestOnlyReservation = corporate?.AllowGuestOnlyReservation;

    error.cbtPassengersForm =
      (await getCbtToken(user, frontendSettings, customerUsername)) &&
      !cbtFormFilled(cbtPassengersForm, allowGuestOnlyReservation);

    if (isHotelSearchForm) {
      if (this.state.to === "") {
        error.to = true;
      }
      if (error.from || error.to || error.cbtPassengersForm) {
        this.setState({ error: { ...error } });
        return false;
      }
    } else {
      switch (this.state.typeSearch) {
        case "ONE_WAY":
        case "RETURN":
          if (this.state.from === "") {
            error.from = true;
          }
          if (this.state.to === "") {
            error.to = true;
          }
          if (error.from || error.to || error.cbtPassengersForm) {
            this.setState({ error: { ...error } });
            return false;
          }
          break;
        case "MULTIPLE":
          Object.keys(query).forEach((key) => {
            if (key.includes("flight_") && query[key].length === 0) {
              error.multiCity = { ...error.multiCity, [key]: true };
            }
          });
          if (
            Object.values(error.multiCity).length > 0 ||
            error.cbtPassengersForm
          ) {
            this.setState({
              error: { ...error, multiCity: { ...error.multiCity } },
            });
            return false;
          }
          break;
        default:
          return false;
      }
    }

    return true;
  };

  search = async () => {
    if (checkIsHotelSearchForm(this.props.router)) {
      return this.searchHotels();
    }
    return this.searchFlights();
  };

  searchFlights = async () => {
    const { cbt, user, frontendSettings, customerUsername } = this.props;
    const cbtToken = await getCbtToken(
      user,
      frontendSettings,
      customerUsername
    );
    if (cbtToken && cbt?.users?.length === 0) {
      return;
    }
    const passengers = this.formatPassengersForQuery();

    const query: any = {
      from:
        (this.state.typeSearch !== "MULTIPLE" ? this.state.from.value : "") ||
        Router.query.from,
      to:
        (this.state.typeSearch !== "MULTIPLE" ? this.state.to.value : "") ||
        Router.query.to,
      flightClass: this.state.flightClass,
      departureDate: this.state.departureDate || Router.query.departureDate,
      returnDate:
        this.state.typeSearch === "RETURN"
          ? this.state.returnDate || Router.query.returnDate
          : "",
      ...passengers,
      date: Date.now(),
    };

    if (this.state.selectedTransportCompany !== "all") {
      query.preferred_airline = this.state.selectedTransportCompany;
    }
    if (this.state.directFlight) {
      query.max_transfers = "direct";
    }
    if (this.state.toleranceDays && this.state.typeSearch !== "MULTIPLE") {
      query.toleranceDays = this.state.toleranceDays;
    }
    if (Router.query.lang) {
      query.lang = Router.query.lang;
    }
    if (Router.query.currency) {
      query.currency = Router.query.currency;
    }

    if (this.state.typeSearch === "MULTIPLE") {
      delete query.from;
      delete query.to;
      delete query.departureDate;
      delete query.returnDate;

      this.state.searchFlights.forEach((oSearchFlight, i) => {
        // query.flights[i + 1] = { origin: oSearchFlight.origin };
        query[`flight_${i + 1}_origin`] = oSearchFlight.origin
          ? oSearchFlight.origin.value
          : "";
        query[`flight_${i + 1}_destination`] = oSearchFlight.destination
          ? oSearchFlight.destination.value
          : "";
        query[`flight_${i + 1}_departureDate`] = oSearchFlight.departureDate;
      });
    }

    if (!(await this.areInputsFilled(query))) {
      return;
    }

    this.redirectToSearchResults({
      searchQuery: query,
      pathname: "/results",
    });
  };

  searchHotels = async () => {
    const searchQuery = {
      INF: this.state.passengers.INF,
      CHD: this.state.passengers.CHD,
      ADT: this.state.passengers.ADT,
      destination: this.state.to.value,
      departureDate: this.state.departureDate || Router.query.departureDate,
      returnDate: this.state.returnDate || Router.query.returnDate,
      typeSearch: "hotels",
    };

    if (Router.query.currency) {
      searchQuery.currency = Router.query.currency;
    }

    if (!(await this.areInputsFilled(searchQuery))) {
      return;
    }

    this.redirectToSearchResults({
      searchQuery,
      pathname: ROUTES.HOTELS,
    });
  };

  redirectToSearchResults = ({ searchQuery, pathname }) => {
    const cbtParams = this.getCbtParams();

    const query = { ...searchQuery, ...cbtParams };

    if (Router.router.route.includes("/iframe")) {
      const str = Object.keys(query)
        .map((key) => `${key}=${encodeURIComponent(query[key])}`)
        .join("&");

      try {
        top.window.location.href = `${window.location.origin}/results?${str}`;
      } catch (e) {
        window.parent.postMessage(
          `${window.location.origin}/results?${str}`,
          "*"
        );
      }
      return;
    }

    Router.push({
      pathname,
      query,
    });
  };

  getCbtParams = () => {
    const {
      cbtPassengersForm,
      user,
      frontendSettings,
      customerUsername,
      cbt: { corporate },
    } = this.props;
    const allowGuestOnlyReservation = corporate?.AllowGuestOnlyReservation;

    return getCbtToken(user, frontendSettings, customerUsername) &&
      cbtFormFilled(cbtPassengersForm, allowGuestOnlyReservation)
      ? {
          userIds: cbtPassengersForm.travellers,
          guests: cbtPassengersForm.guests,
          flightClass: cbtPassengersForm.flightClass,
          costCenterId: cbtPassengersForm.costCenter,
          travelReasonId: cbtPassengersForm.travelReason,
        }
      : {};
  };

  plusCounterHandler = (type: string) => {
    if (type === "ROM") {
      return this.setState({ rooms: this.state.rooms + 1 });
    }

    if (countPassengers(this.state.passengers) > 8) {
      return;
    }

    this.setState({
      passengers: {
        ...this.state.passengers,
        [type]: this.state.passengers[type] + 1,
      },
    });
  };

  minusCounterHandler = (type: string) => {
    if (type === "ROM") {
      if (this.state.rooms > 1) {
        return this.setState({ rooms: this.state.rooms - 1 });
      }
      return;
    }

    if (this.state.passengers[type] < 1) {
      return;
    }

    this.setState({
      passengers: {
        ...this.state.passengers,
        [type]: this.state.passengers[type] - 1,
      },
    });
  };

  setDate = (value, typeValue) => {
    const dateObj = {
      departureDate: this.state.departureDate,
      returnDate: this.state.returnDate,
    };
    if (typeValue === "DEPARTURE") {
      dateObj.departureDate = value;

      if (
        this.state.returnDate < value ||
        (this.props.router.query.typeSearch === "hotels" &&
          this.state.returnDate === value)
      ) {
        if (this.props.router.query.typeSearch === "hotels") {
          const returnDate = new Date(value);
          returnDate.setDate(returnDate.getDate() + 1);
          const returnDateString = `${returnDate.getFullYear()}-${pad2(
            returnDate.getMonth() + 1
          )}-${returnDate.getDate()}`;

          dateObj.returnDate = returnDateString;
        } else {
          dateObj.returnDate = value;
        }
      }
    } else {
      dateObj.returnDate = value;
    }

    this.setState({
      ...dateObj,
    });
  };

  setMultiCityDate = (value: string, index: number): void => {
    const searchFlights = this.state.searchFlights.map((el) => ({ ...el }));
    searchFlights[index].departureDate = value;

    for (let i = 0; i < searchFlights.length - 1; i++) {
      if (searchFlights[i].departureDate > searchFlights[i + 1].departureDate) {
        searchFlights[i + 1].departureDate = searchFlights[i].departureDate;
      }
    }

    this.setState({ searchFlights });
  };

  onChangeFlightClass = (value: string) =>
    this.setState({ flightClass: value });

  onChangeExtendedWeekends = () => {
    this.setState({ weekends: !this.state.weekends });
  };

  onDirectFlightChange = () => {
    this.setState({ directFlight: !this.state.directFlight });
  };

  selectAirline = (val) => {
    this.setState({
      selectedTransportCompany: val.value,
      airlinesTouched: true && val.value !== "all",
    });
  };

  addFlight = () => {
    if (this.state.searchFlights.length > 7) {
      return false;
    }
    this.setState({
      searchFlights: this.state.searchFlights.concat({
        ...searchFlightBase,
        departureDate: this.state.searchFlights[
          this.state.searchFlights.length - 1
        ].departureDate,
      }),
    });
  };

  removeFlight = (indexSearchFlight) => {
    if (this.state.searchFlights.length <= 1) {
      return false;
    }
    this.setState({
      searchFlights: this.state.searchFlights.filter(
        (o, i) => i !== indexSearchFlight
      ),
    });
  };

  render() {
    const {
      intl,
      user,
      module,
      frontendSettings,
      hotelsEnabled,
      router,
      anonymousSearchEnabled,
    } = this.props;
    const { typeSearch } = this.state;
    const isHotelSearchForm = checkIsHotelSearchForm(router);
    const blockSearchDaysBefore =
      parseInt(
        frontendSettings?.dealerFlightSearchSettings?.blockSearchDaysBefore,
        10
      ) || 0;

    const hotelModuleEnabled = module?.includes(MODULES.HOTEL);
    const flightsModuleEnabled = module?.includes(MODULES.AIR);

    if (this.props.router.route === "/register" && !anonymousSearchEnabled) {
      return null;
    }

    const counterSignPlusClass = classnames("counter-sign", {
      "counter-sign-disabled": this.state.searchFlights.length > 7,
    });

    return (
      <div
        className={classnames("header-search-form", {
          iframe: this.props.router.route === "/iframe",
        })}
      >
        <div
          className={classnames("header-search-form-tabs", {
            "header-search-form-tabs-with-hotels": hotelsEnabled,
          })}
        >
          {(!this.props.router.query.typeSearch ||
            this.props.router.query.typeSearch === "flights") &&
            flightsModuleEnabled && (
              <div className="header-search-form-tab-flight-container">
                <div
                  className={classnames("header-search-form-tab", {
                    "header-search-form-tab--active": typeSearch === "ONE_WAY",
                    iframe: this.props.router.route === "/iframe",
                  })}
                  id="header-search-form-tab_ONE_WAY"
                  onClick={() => this.changeTypeSearch("ONE_WAY")}
                  role="button"
                >
                  <Img
                    src="/static/images/ico-plane-oneway.svg"
                    className={classnames("header-search-form-tab-ico", {
                      iframe: this.props.router.route === "/iframe",
                    })}
                    height={15}
                    width={29}
                    alt="plane icon"
                  />

                  <span
                    className={classnames("header-search-form-tab-text", {
                      "header-search-form-tab-text-active":
                        typeSearch === "ONE_WAY",
                      iframe: this.props.router.route === "/iframe",
                    })}
                    id="GOL_package-textStorage-SearchForm.oneWay"
                  >
                    <FormattedMessage id="SearchForm.oneWay" />
                  </span>
                </div>
                <div
                  className={classnames("header-search-form-tab", {
                    "header-search-form-tab--active": typeSearch === "RETURN",
                    iframe: this.props.router.route === "/iframe",
                  })}
                  id="header-search-form-tab_RETURN"
                  onClick={() => this.changeTypeSearch("RETURN")}
                  role="button"
                >
                  <Img
                    src="/static/images/ico-plane-back.svg"
                    className={classnames("header-search-form-tab-ico-return", {
                      iframe: this.props.router.route === "/iframe",
                    })}
                    height={22}
                    width={22}
                    alt="plane icon"
                  />

                  <span
                    className={classnames("header-search-form-tab-text", {
                      iframe: this.props.router.route === "/iframe",
                    })}
                    id="GOL_package-textStorage-SearchForm.return"
                  >
                    <FormattedMessage id="SearchForm.return" />
                  </span>
                </div>
                <div
                  role="button"
                  className={classnames(
                    "header-search-form-tab",
                    { "header-search-form-tab-last": !hotelsEnabled },
                    {
                      "header-search-form-tab--active":
                        typeSearch === "MULTIPLE",
                      iframe: this.props.router.route === "/iframe",
                    }
                  )}
                  id="header-search-form-tab_MULTIPLE"
                  onClick={() => this.changeTypeSearch("MULTIPLE")}
                >
                  <Img
                    src="/static/images/ico-plane-double.svg"
                    className={classnames("header-search-form-tab-ico", {
                      iframe: this.props.router.route === "/iframe",
                    })}
                    height={15}
                    width={36}
                    alt="plane icon"
                  />
                  <span
                    className={classnames("header-search-form-tab-text", {
                      iframe: this.props.router.route === "/iframe",
                    })}
                    id="GOL_package-textStorage-SearchForm.multiCity"
                  >
                    <FormattedMessage id="SearchForm.multiCity" />
                  </span>
                </div>
              </div>
            )}

          {flightsModuleEnabled &&
            this.props.router.query?.typeSearch === "hotels" && (
              <div
                className={classnames("header-search-form-tab", {
                  iframe: this.props.router.route === "/iframe",
                })}
                id="header-search-form-tab_RETURN"
                onClick={() => this.changeTypeSearch("FLIGHTS")}
                role="button"
              >
                <Img
                  src="/static/images/ico-plane-oneway.svg"
                  className={classnames("header-search-form-tab-ico", {
                    iframe: this.props.router.route === "/iframe",
                  })}
                  height={15}
                  width={29}
                  alt="plane icon"
                />

                <span
                  className={classnames("header-search-form-tab-text", {
                    iframe: this.props.router.route === "/iframe",
                  })}
                >
                  <FormattedMessage id="SearchForm.flightTickets" />
                </span>
              </div>
            )}
          {hotelsEnabled && hotelModuleEnabled && (
            <div
              className={classnames(
                "header-search-form-tab header-search-form-tab--change-type-search header-search-form-tab-hotels",
                {
                  "header-search-form-tab--active":
                    this.props.router.query.typeSearch === "hotels",
                  iframe: this.props.router.route === "/iframe",
                }
              )}
              role="button"
              onClick={() => this.changeTypeSearch("HOTELS")}
            >
              <Img
                src="/static/images/ico-hotel.svg"
                className={classnames("header-search-form-tab-ico", {
                  iframe: this.props.router.route === "/iframe",
                })}
                style={{ height: 16 }}
                alt="hotel icon"
              />
              <span
                className={classnames("header-search-form-tab-text", {
                  iframe: this.props.router.route === "/iframe",
                })}
              >
                <FormattedMessage id="SearchForm.hotels" />
              </span>
            </div>
          )}
        </div>
        <div className="header-search-form-inner">
          {(["ONE_WAY", "RETURN"].includes(typeSearch) ||
            isHotelSearchForm) && (
            <div
              className="header-search-form-inner-cols"
              id="searchForm-standard"
            >
              <div className="header-search-form-inner-first-col">
                <div
                  className={classnames("header-search-form-desktop-line", {
                    iframe: this.props.router.route === "/iframe",
                  })}
                >
                  <div
                    className={classnames("header-search-form-inner-field", {
                      iframe: this.props.router.route === "/iframe",
                    })}
                  >
                    <span className="header-search-form-inner-field-label">
                      {" "}
                      {isHotelSearchForm ? (
                        <FormattedMessage id="SearchForm.hotels.destinationLabel" />
                      ) : (
                        <span id="GOL_package-textStorage-SearchForm.from">
                          <FormattedMessage id="SearchForm.from" />
                        </span>
                      )}
                    </span>
                    {isHotelSearchForm ? (
                      <SearchFormHotelSelect
                        intl={intl}
                        testId="to-hotel"
                        defaultValue={this.state.to}
                        // allowDefaultOptions
                        changeValue={(e) => {
                          this.setState({
                            to: e === null ? "" : e,
                            error: { ...this.state.error, to: "" },
                          });
                        }}
                        idJumToAfterTab="to"
                        error={this.state.error.to}
                      />
                    ) : (
                      <SearchFormAirportSelect
                        intl={intl}
                        testId="from"
                        defaultValue={this.state.from}
                        allowDefaultOptions
                        changeValue={(e) =>
                          this.setState({
                            from: e === null ? "" : e,
                            error: { ...this.state.error, from: "" },
                          })
                        }
                        idJumToAfterTab="to"
                        error={this.state.error.from}
                      />
                    )}
                  </div>
                  {!isHotelSearchForm && (
                    <div
                      className={classnames("header-search-form-inner-field ", {
                        iframe: this.props.router.route === "/iframe",
                      })}
                    >
                      <div className="header-search-form-inner-field-label">
                        <span id="GOL_package-textStorage-SearchForm.to">
                          <FormattedMessage id="SearchForm.to" />
                        </span>
                        {typeSearch === "RETURN" ? (
                          <div
                            id="header-search-form-multiCity-redirect-container"
                            style={{ display: "inline-block" }}
                          >
                            <span className="header-search-form-link-divider">
                              |
                            </span>
                            <span
                              id="header-search-form-multiCity-redirect"
                              onClick={() => this.changeTypeSearch("MULTIPLE")}
                              className="header-search-form-link-text"
                              role="button"
                            >
                              <span id="GOL_package-textStorage-SearchForm.differentReturnAirport">
                                <FormattedMessage id="SearchForm.differentReturnAirport" />
                              </span>
                            </span>
                          </div>
                        ) : null}
                      </div>
                      <SearchFormAirportSelect
                        intl={intl}
                        testId="to"
                        defaultValue={this.state.to}
                        changeValue={(e) =>
                          this.setState({
                            to: e === null ? "" : e,
                            error: { ...this.state.error, to: "" },
                          })
                        }
                        error={this.state.error.to}
                      />
                    </div>
                  )}
                </div>
                <DatesRow
                  flightTypeSearch={typeSearch}
                  departureDate={this.state.departureDate}
                  returnDate={this.state.returnDate}
                  setDate={this.setDate}
                  blockSearchDaysBefore={blockSearchDaysBefore}
                />
                <div className="clearfix" />
              </div>
              <PassengerPreferences
                user={user}
                frontendSettings={frontendSettings}
                route={this.props.router.route}
                error={this.state.error.cbtPassengersForm}
                flightClass={this.state.flightClass}
                minusCounterHandler={this.minusCounterHandler}
                plusCounterHandler={this.plusCounterHandler}
                onChangeFlightClass={this.onChangeFlightClass}
                passengers={this.state.passengers}
                rooms={this.state.rooms}
                resetPassengers={() =>
                  this.setState({
                    passengers: getInitialPassengersNumbersFromAllowedTravelerTypes(
                      this.props.allowedTravelerTypes
                    ),
                  })
                }
              />
            </div>
          )}
          {(typeSearch === "MULTIPLE" || EXPORT_HTML_PACKAGE) &&
            !isHotelSearchForm && (
              <div id="searchForm-multiCity">
                {this.state.searchFlights.map(
                  (oSearchFlight, indexSearchFlight) => (
                    <div
                      id={`searchForm-multiCity-row-${indexSearchFlight}`}
                      key={indexSearchFlight}
                      className={classnames("header-search-form-desktop-line", {
                        iframe: this.props.router.route === "/iframe",
                      })}
                    >
                      <div
                        className={classnames(
                          "header-search-form-inner-field header-search-form-desktop-line-one",
                          {
                            iframe: this.props.router.route === "/iframe",
                          }
                        )}
                      >
                        <span className="header-search-form-inner-field-label GOL_package-textStorage-SearchForm.from">
                          {" "}
                          <FormattedMessage id="SearchForm.from" />
                        </span>
                        <SearchFormAirportSelect
                          intl={intl}
                          testId={`from-${indexSearchFlight}`}
                          idJumToAfterTab={`to-${indexSearchFlight}`}
                          defaultOptions={
                            indexSearchFlight === 0
                              ? this.state.defaultAirports
                              : ""
                          }
                          allowDefaultOptions={indexSearchFlight === 0}
                          defaultValue={
                            this.state.searchFlights[indexSearchFlight] !==
                            undefined
                              ? this.state.searchFlights[indexSearchFlight]
                                  .origin
                              : ""
                          }
                          changeValue={(value) => {
                            const { searchFlights } = this.state;
                            searchFlights[indexSearchFlight].origin = value;
                            const errorAttr = `flight_${
                              indexSearchFlight + 1
                            }_origin`;
                            const errorState = {
                              ...this.state.error,
                              multiCity: { ...this.state.error.multiCity },
                            };
                            delete errorState.multiCity[errorAttr];
                            this.setState({
                              searchFlights,
                              error: { ...errorState },
                            });
                          }}
                          error={
                            this.state.error.multiCity[
                              `flight_${indexSearchFlight + 1}_origin`
                            ]
                          }
                        />
                      </div>
                      <div
                        className={classnames(
                          "header-search-form-inner-field header-search-form-desktop-line-one",
                          {
                            iframe: this.props.router.route === "/iframe",
                          }
                        )}
                      >
                        <span
                          className="header-search-form-inner-field-label GOL_package-textStorage-SearchForm.to"
                          // id="GOL_package-textStorage-SearchForm.to"
                        >
                          <FormattedMessage id="SearchForm.to" />
                        </span>
                        <SearchFormAirportSelect
                          intl={intl}
                          testId={`to-${indexSearchFlight}`}
                          defaultValue={
                            this.state.searchFlights[indexSearchFlight] !==
                            undefined
                              ? this.state.searchFlights[indexSearchFlight]
                                  .destination
                              : ""
                          }
                          changeValue={(value) => {
                            const { searchFlights } = this.state;
                            searchFlights[
                              indexSearchFlight
                            ].destination = value;
                            const errorAttr = `flight_${
                              indexSearchFlight + 1
                            }_destination`;
                            const errorState = {
                              ...this.state.error,
                              multiCity: { ...this.state.error.multiCity },
                            };
                            delete errorState.multiCity[errorAttr];
                            this.setState({
                              searchFlights,
                              error: { ...errorState },
                            });
                          }}
                          error={
                            this.state.error.multiCity[
                              `flight_${indexSearchFlight + 1}_destination`
                            ]
                          }
                        />
                      </div>
                      <div
                        className={classnames(
                          "header-search-form-desktop-line-one",
                          {
                            iframe: this.props.router.route === "/iframe",
                          }
                        )}
                      >
                        <div className="header-search-form-inner-field">
                          <span className="header-search-form-inner-field-label GOL_package-textStorage-SearchForm.departureDate">
                            <FormattedMessage id="SearchForm.departureDate" />
                          </span>
                          <DatePicker
                            typeSearch="DEPARTURE"
                            testId={`departure_date_${indexSearchFlight}`}
                            date={oSearchFlight.departureDate}
                            onChange={(value) =>
                              this.setMultiCityDate(value, indexSearchFlight)
                            }
                            disabledTillDate={
                              this.state.searchFlights[
                                indexSearchFlight - 1
                              ] !== undefined
                                ? new Date(
                                    this.state.searchFlights[
                                      indexSearchFlight - 1
                                    ].departureDate
                                  )
                                : moment()
                                    .add(blockSearchDaysBefore, "days")
                                    .toDate()
                            }
                          />
                        </div>
                      </div>
                      <div className="header-search-form-multiple-add">
                        {indexSearchFlight !== 0 && (
                          <div
                            role="button"
                            onClick={() => this.removeFlight(indexSearchFlight)}
                            className="header-search-form-multiple-add-inner-container-minus"
                          >
                            <Img
                              className="header-search-form-multiple-add-inner-image"
                              src="/static/images/ico-minus-gray.svg"
                              id={`searchForm-multiCity-button-remove-${indexSearchFlight}`}
                              alt="minus icon"
                            />
                            <span
                              id={`GOL_package-textStorage-SearchForm.remove-${indexSearchFlight}`}
                              className="header-search-form-multiple-add-minus-button-label GOL_package-textStorage-SearchForm.remove"
                            >
                              <FormattedMessage id="SearchForm.remove" />
                            </span>
                          </div>
                        )}
                        {indexSearchFlight ===
                          this.state.searchFlights.length - 1 && (
                          <div
                            role="button"
                            onClick={this.addFlight}
                            className="header-search-form-multiple-add-inner-container-plus"
                          >
                            <Img
                              className={`${counterSignPlusClass} header-search-form-multiple-add-inner-image`}
                              src="/static/images/ico-plus.svg"
                              id={`searchForm-multiCity-button-add-${indexSearchFlight}`}
                              alt="plus icon"
                            />
                            <span
                              id={`GOL_package-textStorage-SearchForm.anotherFlight-${indexSearchFlight}`}
                              className="header-search-form-multiple-add-plus-button-label GOL_package-textStorage-SearchForm.anotherFlight"
                            >
                              <FormattedMessage id="SearchForm.anotherFlight" />
                            </span>
                          </div>
                        )}
                      </div>
                      {indexSearchFlight !==
                        this.state.searchFlights.length - 1 && (
                        <div className="header-search-form-multiple-divider" />
                      )}
                    </div>
                  )
                )}
                <PassengerPreferences
                  multi
                  user={user}
                  frontendSettings={frontendSettings}
                  route={this.props.router.route}
                  resetPassengers={() =>
                    this.setState({
                      passengers: getInitialPassengersNumbersFromAllowedTravelerTypes(
                        this.props.allowedTravelerTypes
                      ),
                    })
                  }
                  error={this.state.error.cbtPassengersForm}
                  flightClass={this.state.flightClass}
                  minusCounterHandler={this.minusCounterHandler}
                  plusCounterHandler={this.plusCounterHandler}
                  onChangeFlightClass={this.onChangeFlightClass}
                  passengers={this.state.passengers}
                  rooms={this.state.rooms}
                />
              </div>
            )}
        </div>

        <FilterBar
          intl={intl}
          selectAirlines={this.selectAirline}
          selectedTransportCompany={this.state.selectedTransportCompany}
          search={this.search}
          toleranceDays={this.state.toleranceDays}
          increaseTolerance={this.increaseTolerance}
          decreaseTolerance={this.decreaseTolerance}
          weekends={this.state.weekends}
          onDirectFlightChange={this.onDirectFlightChange}
          extendedWeekend={this.onChangeExtendedWeekends}
          variableDays={
            !isHotelSearchForm &&
            typeSearch !== "MULTIPLE" &&
            plusMinusDaysEnabled(this.props.frontendSettings)
          }
          directFlights={this.state.directFlight}
          airlinesTouched={this.state.airlinesTouched}
        />
        <FilterBarMobile
          intl={intl}
          selectAirlines={this.selectAirline}
          selectedTransportCompany={this.state.selectedTransportCompany}
          search={this.search}
          toleranceDays={this.state.toleranceDays}
          increaseTolerance={this.increaseTolerance}
          decreaseTolerance={this.decreaseTolerance}
          weekends={this.state.weekends}
          onDirectFlightChange={this.onDirectFlightChange}
          extendedWeekend={this.onChangeExtendedWeekends}
          variableDays={
            !isHotelSearchForm &&
            typeSearch !== "MULTIPLE" &&
            plusMinusDaysEnabled(this.props.frontendSettings)
          }
          directFlights={this.state.directFlight}
          airlinesTouched={this.state.airlinesTouched}
        />
      </div>
    );
  }
}

const getCorrectDepartureDaysNumber = (
  departureDays: number,
  blockSearchDaysBefore: number | undefined
): number => {
  if (Number.isNaN(blockSearchDaysBefore)) {
    return departureDays;
  }

  if (departureDays >= blockSearchDaysBefore) {
    return departureDays;
  }

  return blockSearchDaysBefore;
};

function getInitialPassengersNumbersFromAllowedTravelerTypes(
  allowedTravelerTypes
) {
  const oNumberOfPassengers = {};

  allowedTravelerTypes.forEach((allowedTravelerType, index) => {
    oNumberOfPassengers[allowedTravelerType.Code] = index === 0 ? 1 : 0; // only first available prefix has 1 predefined traveller
  });

  return oNumberOfPassengers;
}

const mapStateToProps = (state: AppState) => ({
  destinations: state.storage.airportDestinations,
  defaultAirportSuggestions: state.storage.defaultAirportSuggestions,
  currentLanguage: state.requestorConfig.currentLanguage,
  hotelsEnabled: state.requestorConfig.hotelsEnabled,
  frontendSettings: state.storage.frontendSettings,
  module: state.requestorConfig.module,
  departureDays: getCorrectDepartureDaysNumber(
    state.storage.departureDays,
    parseInt(
      state.storage.frontendSettings?.dealerFlightSearchSettings
        ?.blockSearchDaysBefore,
      10
    )
  ),
  durationOfStay: state.storage.durationOfStay,
  airlines: state.storage.airlines,
  user: state.user,
  cbtPassengersForm: state.cbt.passengersForm,
  searchQuery: state.storage.searchQuery,
  anonymousSearchEnabled:
    state.storage.frontendSettings?.dealerCorporateSettings
      ?.enableAnonymousSearch === "true",
  customerUsername: state.user.email,
  cbt: state.cbt,
  allowedTravelerTypes: state.requestorConfig.allowedTravelerTypes,
  carrierFilter: state.storage.frontendSettings.carrierFilter,
});

export default connect(mapStateToProps)(
  withRouter(injectIntl(HeaderSearchForm))
);
