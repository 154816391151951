export const searchFlightBase = {
  origin: "",
  destination: "",
  departureDate: "",
  returnDate: "",
};

export const sortOptions = [
  {
    value: "PRICE",
    label: "Nejlevnější lety",
  },
  {
    value: "NUM_TRANSFERS",
    label: "Nejméně přestupů",
  },
  {
    value: "FLIGHT_LENGTH",
    label: "Nejkratší doba letu",
  },
  {
    value: "TIME_DEPARTURE",
    label: "Nejdřívější časy odletu",
  },
  {
    value: "PLACE_DEPARTURE",
    label: "Podle místa odletu",
  },
  {
    value: "PLACE_ARRIVAL",
    label: "Podle cíle cesty",
  },
];

export const passengerCodesIntl = {
  ADT: "PassengerCodes.ADT",
  INF: "PassengerCodes.INF",
  CHD: "PassengerCodes.CHD",
  YTH: "PassengerCodes.YTH",
  YCD: "PassengerCodes.YCD",
};

export const salutationTranslation = {
  mr: "Salutation.Mr",
  ms: "Salutation.Ms",
  mrs: "Salutation.Mrs",
  miss: "Salutation.Miss",
  mstr: "Salutation.Mstr",
  "dr-m": "Salutation.dr-m",
  "dr-f": "Salutation.dr-f",
  "prof-m": "Salutation.prof-m",
  "prof-f": "Salutation.prof-f",
  shk: "Salutation.shk",
  shka: "Salutation.shka",
};

export const CLIENT_TOKEN_EXPIRATION_TIME = 3600 * 1000; // 1 hour
export const ANONYMOUS_CLIENT_TOKEN_EXPIRATION_TIME = 1200 * 1000;

// dynamic form v2
export const BOOKING_FORM_FIELD_EMAIL_INSIDE = "_email";
export const BOOKING_FORM_CONTACT_FIELDS = {
  contact_title: "namePrefix", //
  contact_firstname: "givenName", //
  contact_lastname: "surname", //
  contact_phone_number: "telephone", //
  contact_name: "name",
  contact_email: "email",
  contact_phone_country: "telephone",
};
export const BOOKING_FORM_BILLING_FIELDS = {
  company_billing_company: "company", //
  company_billing_id: "ic", //
  company_billing_tax_id: "dic", //
  company_billing_street: "street", //
  company_billing_city: "city",
  company_billing_zipcode: "postalCode",
  company_billing_country: "country",
};
export const BOOKING_FORM_PERSON_FIELDS = {
  "0-passenger_title": "namePrefix",
  "0-passenger_firstname": "firstname",
  "0-passenger_lastname": "surname",
  "0-passenger_birth_date": "birthDate",
  "0-passenger_passport_number": "passportNumber",
  "0-passenger_passport_validity": "ValidityDate",
  "0-passenger_citizenship": "Citizenship",
  "0-passenger_passport_country_of_issue": "Issued",
  "0-passenger_email": "email",
  "0-passenger_frequent_flyer": "-",
  "0-passenger_frequent_flyer_number": "-",
};
export const BOOKING_FORM_CBT_TRAVELERS_CLEAN_FIELDS = {
  title: "NamePrefix",
  firstname: "FirstNameLatin",
  lastname: "LastNameLatin",
  email: "Email",
  phone_country: "PhoneNumber",
  birth_date: "BirthDate",
  DateOfBirth: "BirthDate",
};
export const BOOKING_FORM_TRAVELERS_CLEAN_PASSPORT_FIELDS = {
  citizenship: "Citizenship",
  passport_country_of_issue: "Issued",
  passport_number: "Number",
  passport_validity: "ValidityDate",
};
export const BOOKING_FORM_CBT_TRAVELERS_CLEAN_PASSPORT_FIELDS = {
  citizenship: "Citizenship",
  passport_country_of_issue: "CountryOfIssue",
  passport_number: "Number",
  passport_validity: "Validity",
};
