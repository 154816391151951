import {
  getDefaultOptionValueFromBookingElement,
  isBookingFormElementRequired,
} from "../../lib/bookingFormHelpers";
import { pad2 } from "../../lib/functions";

export function getBookingFormElementDescription({
  bookingFormElement,
  passengerIndex,
  cbt,
}) {
  return {
    name: bookingFormElement.Name,
    required: isBookingFormElementRequired({
      bookingFormElement,
      passengerIndex,
      cbt,
    }),
    type: bookingFormElement.Type,
    label: bookingFormElement?.Label?.$t,
    format: bookingFormElement?.Format,
    options: getOptionsFromBookingElement({
      bookingFormElement,
      passengerIndex,
    }),
    defaultOptionValue: getDefaultOptionValueFromBookingElement(
      bookingFormElement
    ),
    dateRange: getDateRangeFromBookingElement(bookingFormElement),
  };
}

function getDateRangeFromBookingElement(bookingFormElement) {
  if (!bookingFormElement?.DateRange) {
    if (bookingFormElement.Name?.endsWith("_validity")) {
      const today = new Date();
      const dayAfter20Years = new Date(
        today.getFullYear() + 20,
        today.getMonth(),
        today.getDate()
      );
      return {
        from: `${today.getFullYear()}-${pad2(today.getMonth() + 1)}-${pad2(
          today.getDate()
        )}`,
        to: `${dayAfter20Years.getFullYear()}-${pad2(
          dayAfter20Years.getMonth() + 1
        )}-${pad2(dayAfter20Years.getDate())}`,
      };
    }

    return;
  }

  return {
    from: bookingFormElement.DateRange.DateFrom,
    to: bookingFormElement.DateRange.DateTo,
  };
}

function getOptionsFromBookingElement({ bookingFormElement, passengerIndex }) {
  if (!bookingFormElement.DataSource) {
    return;
  }

  if (bookingFormElement.DataSource.Type === "list") {
    return bookingFormElement.DataSource.Options.Option.map((option) => ({
      label: option.OptionText.$t,
      value: option.Key,
      optionAlertName: option?.BookingFormAlert,
      ...(option.Appends?.Append[0] !== undefined && {
        data: {
          price: option.Appends.Append[0].AdditionalService.Amount,
          amountPerPassenger:
            option.Appends.Append[0].AdditionalService.AmountPerPassenger ??
            null,
          currency: option.Appends.Append[0].AdditionalService.CurrencyCode,
          styleClass:
            option.Appends.Append?.filter(
              (appendData) => appendData?.Key === "styleClasses"
            )?.[0]?.AppendData?.$t ?? "",
          description:
            option?.Appends?.Append?.filter(
              (descriptionData) => descriptionData?.Key === "text"
            )?.[0]?.AppendData?.$t ?? "",
          serviceType: option.Appends.Append[0].AdditionalService.Service,
          serviceName: bookingFormElement.Name,
          name: `${passengerIndex ? `${passengerIndex}-` : ""}servicePrices`,
          ref:
            option.Appends.Append.find((opt) => opt.Key === "reference")
              ?.AppendData?.$t ?? null,
        },
      }),
    }));
  }

  if (bookingFormElement.DataSource.Type === "link") {
    return {
      type: "link",
      GolApiRequest: bookingFormElement.DataSource.GolApiRequest.$t,
    };
  }

  if (bookingFormElement.DataSource.Type === "codebook") {
    return {
      type: "codebook",
      codeBookLink: bookingFormElement.DataSource.CodeBookLink.$t,
    };
  }

  return [];
}
