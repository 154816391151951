import { resolveHotelsPoliciesGOL } from "../cbt/api/hotelsPolicies";
import { createResponse } from "../lib/functions";
import { ResponseObject } from "../lib/golObjectTypes/ResponseObject";
import Logger from "../services/Logger";
import golApiCall from "./golApiCall";

const getGolRequestSearchHotelsQuery = ({
  destination,
  ADT,
  CHD,
  INF,
  departureDate,
  returnDate,
  cbtPassengersForm,
}) => {
  const countADT = cbtPassengersForm
    ? cbtPassengersForm.travellers.length + cbtPassengersForm.guests
    : ADT || 0;

  return {
    GolApi: {
      RequestDetail: {
        SearchHotelsRequest_2: {
          HotelLocalities: {
            HotelLocality: {
              Destination: destination,
              CheckInDate: departureDate,
              CheckOutDate: returnDate,
            },
          },
          SearchedRooms: {
            SearchedRoom: {
              Code: "SB",
              Count: countADT,
              Cots: INF || 0,
              ExtraBed: {
                Count: CHD || 0,
                Age: "11",
              },
            },
          },
        },
      },
    },
  };
};

function prepareCbtParams({
  hotelsExtendedResp,
  cbtPassengersForm,
  cbtToken,
  departureDate,
  returnDate,
  customerUsername,
  cbtApiUrl,
}) {
  if (!cbtToken) return {};

  return {
    cbtToken,
    userIds: cbtPassengersForm.travellers,
    guests: cbtPassengersForm.guests,
    costCenterId: cbtPassengersForm.costCenter,
    travelReasonId: cbtPassengersForm.travelReason,
    searchFlightsExtendedResp: hotelsExtendedResp,
    departureDate,
    returnDate,
    customerUsername,
    cbtApiUrl,
  };
}

export default async ({
  destination,
  departureDate,
  returnDate,
  ADT,
  CHD,
  INF,
  cbtToken,
  cbtPassengersForm,
  customerUsername,
  cbtApiUrl,
  alternativeCurrency,
}): Promise<ResponseObject> => {
  const golRequest = getGolRequestSearchHotelsQuery({
    destination,
    departureDate,
    ADT,
    CHD,
    INF,
    returnDate,
    cbtPassengersForm,
  });

  const hotelsExtendedResp = await golApiCall(golRequest, {
    ...(alternativeCurrency ? { alternativeCurrency } : {}),
  });
  const resp = createResponse(hotelsExtendedResp);
  if (!resp.success) {
    Logger.error(resp);
    return {
      isError: true,
    };
  }

  let cbtResolve;
  if (resp.data.HotelOffers.HotelOffer.length > 0) {
    cbtResolve = await resolveHotelsPoliciesGOL(
      prepareCbtParams({
        hotelsExtendedResp,
        cbtPassengersForm,
        cbtToken,
        departureDate,
        returnDate,
        customerUsername,
        cbtApiUrl,
      })
    );
  }

  return {
    ...resp,
    ...(cbtResolve
      ? {
          travelPolicies: cbtResolve.data.TravelPolicies.reduce((acc, pol) => {
            acc[pol.Id] = pol;
            return acc;
          }, {}),
          travelPoliciesResults: cbtResolve.data.TravelPoliciesResults,
        }
      : {}),
  };
};
