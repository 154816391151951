import React, { ReactElement, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { AppState } from "shared/data/reducers";

import Img from "@components/FileServer/Img";

import { useOutsideClick } from "@lib/webFunctions";

type ConfigType = {
  currentLanguage: string | null;
  supportedLanguages: Array<{ Language: string }>;
};

function SetLanguage(): ReactElement {
  const { currentLanguage, supportedLanguages }: ConfigType = useSelector(
    (state: AppState) => state.requestorConfig
  );
  const [isMenuOpen, toggleMenu] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  const formattedLanguages = supportedLanguages.map((supportedLanguage) => {
    const { Language } = supportedLanguage;
    return {
      Language,
    };
  });

  useOutsideClick(ref, () => {
    if (isMenuOpen) toggleMenu(false);
  });

  return (
    <div
      onClick={() => toggleMenu(!isMenuOpen)}
      ref={ref}
      role="button"
      className="header-menu-languages"
    >
      <span className="header-menu-languages-selected">
        {currentLanguage && (currentLanguage as string).toUpperCase()}
      </span>
      <Img
        src="/static/images/ico-arrow-down.svg"
        className="header-menu-languages-ico"
        style={{
          transition: "all .4s ease",
          transform: isMenuOpen ? "rotate(180deg)" : null,
        }}
        alt="arrow down icon"
      />
      {isMenuOpen && (
        <div className="header-menu-languages-tooltip">
          {formattedLanguages.map((supportedLanguage) => (
            <a
              key={supportedLanguage.Language}
              onClick={() => changeLanguage(supportedLanguage.Language)}
              role="button"
            >
              {supportedLanguage.Language.toUpperCase()}
            </a>
          ))}
        </div>
      )}
    </div>
  );
}

export const changeLanguage = (language: string): void => {
  const url = window.location.href;
  let newUrl = "";
  if (url.includes("lang=")) {
    // eslint-disable-next-line security/detect-unsafe-regex -- no harm regex
    newUrl = url.replace(/([?&])lang=[^&]*(&.*)?/, `$1lang=${language}$2`);
  } else if (url.includes("?")) {
    newUrl = `${url}&lang=${language}`;
  } else {
    newUrl += `${url}?lang=${language}`;
  }
  window.location.href = newUrl;
};

export default SetLanguage;
