import classnames from "classnames";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { useCbtToken, useFetchCbtPassengersOptions } from "shared/cbt/hooks";
import { clearErrors, closeForm } from "shared/data/actions/cbt";
import { AppState } from "shared/data/reducers";
import { capitalize } from "shared/lib/functions";
import { cutString } from "shared/lib/stringFormat";

import Img from "@components/FileServer/Img";
import { PassengersOptionsDialog } from "@components/SearchForm/PassengersOptionsDialog";
import SearchFormProperties, {
  getFlightClasses,
} from "@components/SearchForm/PropertiesSelect";
import FormattedMessage from "@components/UI/FormattedMessage";

export function Preferences({
  className,
  route,
  intl,
  passengers,
  plusCounterHandler,
  minusCounterHandler,
  onChangeFlightClass,
  flightClass,
  rooms,
  resetPassengers,
}) {
  return (
    <div
      className={classnames(className, {
        iframe: route === "/iframe",
      })}
    >
      <span
        className="header-search-form-inner-field-label"
        id="GOL_package-textStorage-SearchForm.options"
      >
        <FormattedMessage id="SearchForm.options" />
      </span>
      <SearchFormProperties
        intl={intl}
        passengers={passengers}
        resetPassengers={resetPassengers}
        rooms={rooms}
        onAddCounterHandler={plusCounterHandler}
        onRemoveCounterHandler={minusCounterHandler}
        onChangeFlightClass={onChangeFlightClass}
        flightClass={flightClass}
        selectComponentPrefix="header-search-form"
        maxNumPassengers={9}
      />
    </div>
  );
}

export function cbtFormFilled(
  passengersForm,
  allowGuestOnlyReservation: boolean
) {
  return (
    (allowGuestOnlyReservation
      ? passengersForm.travellers.length + passengersForm.guests > 0
      : passengersForm.travellers.length > 0) &&
    passengersForm.costCenter &&
    passengersForm.travelReason
  );
}

export function cbtPartiallyFilled(
  passengersForm,
  allowGuestOnlyReservation: boolean
) {
  return (
    !cbtFormFilled(passengersForm, allowGuestOnlyReservation) &&
    passengersForm.wasClosed &&
    (passengersForm.travellers.length !== 0 ||
      passengersForm.guests !== 0 ||
      passengersForm.costCenter ||
      passengersForm.travelReason)
  );
}

export function cbtFormGuestsOnlyNotAllowed(
  passengersForm,
  allowGuestOnlyReservation: boolean
) {
  return (
    !allowGuestOnlyReservation &&
    passengersForm.guests > 0 &&
    passengersForm.travellers.length === 0
  );
}

export function CbtPassengersOptions({
  route,
  cbtToken,
  error,
  className,
  rooms,
  minusCounterHandler,
  plusCounterHandler,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const cbt = useSelector((state: AppState) => state.cbt);
  const {
    passengersForm,
    usersCache,
    costCenters,
    travelReasons,
    corporate,
  } = cbt;

  const allowGuestOnlyReservation = corporate?.AllowGuestOnlyReservation;
  const dispatch = useDispatch();
  const intl = useIntl();
  useFetchCbtPassengersOptions(dispatch, cbtToken, true);

  const flightClasses = getFlightClasses(intl);
  const formNotFilled = !cbtFormFilled(
    passengersForm,
    allowGuestOnlyReservation
  );
  const formPartiallyFilled = cbtPartiallyFilled(
    passengersForm,
    allowGuestOnlyReservation
  );
  const guestsOnlyNotAllowed = cbtFormGuestsOnlyNotAllowed(
    passengersForm,
    allowGuestOnlyReservation
  );

  return (
    <div
      className={classnames(className, {
        iframe: route === "/iframe",
      })}
    >
      <span className="header-search-form-inner-field-label">
        <FormattedMessage id="SearchForm.cbtPassengersAndTripOptions" />
      </span>
      <div>
        <button
          type="button"
          className={classnames("header-search-form-inner-field-text", {
            "form-filled": !formNotFilled,
          })}
          onClick={() => setIsOpen((state) => !state)}
          aria-haspopup="true"
          aria-expanded={isOpen}
        >
          {formNotFilled || guestsOnlyNotAllowed ? (
            <div>
              <div style={{ marginBottom: "4px" }}>
                <FormattedMessage id="SearchForm.cbtSelectAdditionalMandatoryInformation" />
              </div>
              {((error || formPartiallyFilled) && !isOpen && (
                <div className="search-field-error">
                  <FormattedMessage id="SearchForm.fillInAllCbtRequiredInfoError" />
                </div>
              )) ||
                (guestsOnlyNotAllowed && !isOpen && (
                  <div className="search-field-error">
                    <FormattedMessage id="SearchForm.cbtGuestsOnlyNotAllowed" />
                  </div>
                ))}
            </div>
          ) : (
            <div className="header-search-form__cbt-passengers">
              <div className="header-search-form__cbt-passengers-main-info">
                <FormattedMessage
                  id="General.travellersCount"
                  values={{ travelers: passengersForm.travellers.length }}
                />
                {corporate?.AllowGuests && (
                  <>
                    ,{" "}
                    <FormattedMessage
                      id="General.guestsCount"
                      values={{ guests: passengersForm.guests }}
                    />
                  </>
                )}
              </div>
              <div className="header-search-form__cbt-passengers-main-info">
                {passengersForm.flightClass &&
                  capitalize(
                    flightClasses
                      .find((fc) => fc.value === passengersForm.flightClass)
                      .label.toLocaleLowerCase()
                  )}
              </div>
              <div className="header-search-form__cbt-passengers-additional-info">
                <FormattedMessage id="General.costCentre" />:{" "}
                <span id="cost-center-name" className="bold">
                  {cutString(
                    costCenters.find(
                      (cc) => cc.value === passengersForm.costCenter
                    )?.label,
                    16
                  )}
                </span>
              </div>
              <div>
                <FormattedMessage id="General.travelReason" />:{" "}
                <span id="travel-reason-name" className="bold">
                  {cutString(
                    travelReasons.find(
                      (tr) => tr.value === passengersForm.travelReason
                    )?.label,
                    18
                  )}
                </span>
              </div>
            </div>
          )}
          <Img
            src="/static/images/ico-arrow-down.svg"
            className={classnames({
              "select-arrow-rotate-up": isOpen,
            })}
            style={{ marginLeft: "8px" }}
            alt="down arrow icon"
          />
        </button>
        {isOpen && (
          <PassengersOptionsDialog
            onClose={() => {
              setIsOpen((state) => !state);
              dispatch(clearErrors());
              dispatch(closeForm());
            }}
            passengersForm={passengersForm}
            users={usersCache}
            flightClasses={flightClasses}
            costCenters={costCenters}
            travelReasons={travelReasons}
            corporate={corporate}
            cbtToken={cbtToken}
            rooms={rooms}
            minusCounterHandler={minusCounterHandler}
            plusCounterHandler={plusCounterHandler}
          />
        )}
      </div>
    </div>
  );
}

export default function PassengerPreferences({
  user,
  frontendSettings,
  route,
  error,
  flightClass,
  minusCounterHandler,
  onChangeFlightClass,
  passengers,
  rooms,
  plusCounterHandler,
  multi = false,
  resetPassengers,
}) {
  const intl = useIntl();
  const [cbtToken] = useCbtToken(user, frontendSettings);

  return cbtToken ? (
    <CbtPassengersOptions
      className={
        multi
          ? "header-search-form-multiple-cbt-passengers"
          : "header-search-form-desktop-line-one cbt-preferences"
      }
      route={route}
      cbtToken={cbtToken}
      error={error}
      rooms={rooms}
      minusCounterHandler={minusCounterHandler}
      plusCounterHandler={plusCounterHandler}
    />
  ) : (
    <Preferences
      className={
        multi
          ? "header-search-form-inner-field header-search-form-multiple-preferences preferences"
          : "header-search-form-inner-field header-search-form-desktop-line-one preferences"
      }
      flightClass={flightClass}
      intl={intl}
      minusCounterHandler={minusCounterHandler}
      onChangeFlightClass={onChangeFlightClass}
      passengers={passengers}
      resetPassengers={resetPassengers}
      rooms={rooms}
      plusCounterHandler={plusCounterHandler}
      route={route}
    />
  );
}
