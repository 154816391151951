import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { PassengersCount } from "shared/lib/golObjectTypes/PassengerTypes";
import errorTranslation from "shared/lib/passengersSelect/errorTranslation";

import Counter from "@components/SearchForm/Counter";
import FormattedMessage from "@components/UI/FormattedMessage";
import SimpleSelect from "@components/UI/SimpleSelect";

import { AppState } from "@shared/data/reducers";

interface IPassengersSelect {
  isHotelSearchForm: boolean;
  passengers: PassengersCount;
  flightClasses?: Array<{ value: string; label: string }>;
  valueFlightClass?: { value: string; label: string };
  onAddCounterHandler: (type: string) => void;
  onRemoveCounterHandler: (type: string) => void;
  onChangeFlightClass: (flightClass: string) => void;
  allowClassSelection?: boolean;
  defaultFlightClassName?: string;
  displayShowToggleProperties?: boolean;
  toggleShowProperties: () => void;
  error?: boolean;
  errorMessage?: number;
  selectComponentPrefix: string;
  numPassengers: number;
  maxNumPassengers: number;
}

const PassengersSelect: React.FC<IPassengersSelect> = ({
  isHotelSearchForm,
  passengers,
  onAddCounterHandler,
  onRemoveCounterHandler,
  onChangeFlightClass,
  flightClasses,
  valueFlightClass,
  allowClassSelection,
  defaultFlightClassName,
  displayShowToggleProperties,
  toggleShowProperties,
  error,
  errorMessage,
  selectComponentPrefix,
  numPassengers,
  maxNumPassengers,
}) => {
  const intl = useIntl();

  const errorTranslations = errorTranslation(intl);

  const { allowedTravelerTypes } = useSelector((state: AppState) => ({
    allowedTravelerTypes: state.requestorConfig.allowedTravelerTypes,
  }));

  return (
    <table className={`${selectComponentPrefix}-properties-table`}>
      <tbody>
        {isHotelSearchForm &&
          allowedTravelerTypes.map((allowedTravelerType) => {
            if (["INF", "CHD", "ADT"].includes(allowedTravelerType.Code)) {
              return (
                <tr
                  key={allowedTravelerType.Code}
                  className={`${selectComponentPrefix}-properties-row`}
                >
                  <td>
                    <span
                      id={`${selectComponentPrefix}-GOL_package-textStorage-PassengerCodes.${allowedTravelerType.Code}`}
                    >
                      {allowedTravelerType.$t}
                    </span>{" "}
                    <small>
                      {intl.formatMessage({
                        id: `PassengerCodes.${allowedTravelerType.Code}.Description`,
                      }) !==
                      `PassengerCodes.${allowedTravelerType.Code}.Description`
                        ? intl.formatMessage({
                            id: `PassengerCodes.${allowedTravelerType.Code}.Description`,
                          })
                        : ""}
                    </small>
                  </td>
                  <td>
                    <Counter
                      type={allowedTravelerType.Code}
                      disabledMinus={passengers[allowedTravelerType.Code] === 0}
                      disabledPlus={numPassengers >= maxNumPassengers}
                      value={passengers[allowedTravelerType.Code]}
                      onPlusSign={onAddCounterHandler}
                      onMinusSign={onRemoveCounterHandler}
                    />
                  </td>
                </tr>
              );
            }
            return null;
          })}
        {!isHotelSearchForm && (
          <>
            <tr>
              <th
                className={`${selectComponentPrefix}-properties-class-name`}
                id={`${selectComponentPrefix}-GOL_package-textStorage-PropertiesSelect.class`}
              >
                <FormattedMessage id="PropertiesSelect.class" />:
              </th>
              <th
                className={`${selectComponentPrefix}-properties-class-select`}
              >
                {allowClassSelection === false ? (
                  <span>{defaultFlightClassName.toUpperCase()}</span>
                ) : (
                  <SimpleSelect
                    testId="search-properties"
                    options={flightClasses}
                    onChange={({ value }) => {
                      onChangeFlightClass(value);
                    }}
                    isSearchable={false}
                    value={valueFlightClass}
                  />
                )}
              </th>
            </tr>

            {/* {new passengers} */}
            {allowedTravelerTypes.map((allowedTravelerType) => (
              <tr
                key={allowedTravelerType.Code}
                className={`${selectComponentPrefix}-properties-row`}
              >
                <td>
                  <span
                    id={`${selectComponentPrefix}-GOL_package-textStorage-PassengerCodes.${allowedTravelerType.Code}`}
                  >
                    {allowedTravelerType.$t}
                  </span>{" "}
                  <small>
                    {intl.formatMessage({
                      id: `PassengerCodes.${allowedTravelerType.Code}.Description`,
                    }) !==
                    `PassengerCodes.${allowedTravelerType.Code}.Description`
                      ? intl.formatMessage({
                          id: `PassengerCodes.${allowedTravelerType.Code}.Description`,
                        })
                      : ""}
                  </small>
                </td>
                <td>
                  <Counter
                    type={allowedTravelerType.Code}
                    disabledMinus={passengers[allowedTravelerType.Code] === 0}
                    disabledPlus={numPassengers >= maxNumPassengers}
                    value={passengers[allowedTravelerType.Code]}
                    onPlusSign={onAddCounterHandler}
                    onMinusSign={onRemoveCounterHandler}
                  />
                </td>
              </tr>
            ))}
          </>
        )}
        <div className="max-passengers-error">
          {numPassengers >= maxNumPassengers && (
            <FormattedMessage id="PropertiesSelect.maxPassengersError" />
          )}
        </div>
        {displayShowToggleProperties && (
          <tr>
            {error ? (
              <td
                id={`${selectComponentPrefix}-properties-error-message`}
                style={{
                  color: "red",
                  fontWeight: "bold",
                  fontSize: "13px",
                }}
              >
                {errorTranslations[errorMessage]}
              </td>
            ) : (
              <td />
            )}
            <td style={{ textAlign: "center" }}>
              <button
                id={`${selectComponentPrefix}-div_toggle_properties_done`}
                onClick={toggleShowProperties}
                className="button button-secondary-color"
                style={{ width: "70%", marginTop: 5 }}
                type="button"
              >
                <FormattedMessage id="PropertiesSelect.done" />
              </button>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default PassengersSelect;
