import classNames from "classnames";

import Img from "@components/FileServer/Img";

type Props = {
  onMinusSign: (type: string) => void;
  onPlusSign: (type: string) => void;
  value: number;
  type?: string;
  disabledPlus?: boolean;
  disabledMinus?: boolean;
};

const Counter = ({
  onMinusSign,
  onPlusSign,
  value,
  type,
  disabledMinus,
  disabledPlus,
}: Props) => {
  const counterSignMinusClass = classNames("counter-sign", {
    "counter-sign-disabled": disabledMinus === true,
  });

  const counterSignPlusClass = classNames("counter-sign", {
    "counter-sign-disabled": disabledPlus === true,
  });

  return (
    <div className="counter-container">
      <Img
        className={counterSignMinusClass}
        src="/static/images/ico-minus.svg"
        onClick={() => onMinusSign(type)}
        id={`properties_counter_${type}_minus`}
        alt="minus icon"
      />
      <span id={`properties_counter_${type}_value`}>{value}</span>
      <Img
        className={counterSignPlusClass}
        src="/static/images/ico-plus.svg"
        onClick={() => onPlusSign(type)}
        id={`properties_counter_${type}_plus`}
        alt="plus icon"
      />
    </div>
  );
};

export default Counter;
