import { toArray } from "../../lib/functions";
import * as actionTypes from "./actionTypes";

export function getCbtData(resp) {
  return resp?.success ? resp.data : [];
}

export function usersFetched(responseData) {
  return {
    type: actionTypes.CBT_USERS_FETCHED,
    responseData: getCbtData(responseData),
  };
}

export function guestsFetched(responseData) {
  return {
    type: actionTypes.CBT_GUESTS_FETCHED,
    responseData: getCbtData(responseData),
  };
}

export function managersFetched(responseData) {
  return {
    type: actionTypes.CBT_MANAGERS_FETCHED,
    responseData: getCbtData(responseData),
  };
}

export function costCentersFetched(responseData) {
  return {
    type: actionTypes.CBT_COST_CENTERS_FETCHED,
    responseData: getCbtData(responseData),
  };
}

export function travelReasonsFetched(responseData) {
  return {
    type: actionTypes.CBT_TRAVEL_REASONS_FETCHED,
    responseData: getCbtData(responseData),
  };
}

export function corporateFetched(responseData) {
  return {
    type: actionTypes.CBT_CORPORATE_FETCHED,
    corporate: getCbtData(responseData),
  };
}

export function cbtPassengersFormParamsFromQuery(query) {
  if (!query) {
    return null;
  }

  const {
    userIds,
    guests,
    flightClass,
    costCenterId,
    travelReasonId,
    policyResolvedId,
  } = query;
  if (!costCenterId || !travelReasonId) return null; // !userIds || !flightClass ||

  return {
    travellers: userIds ? toArray(userIds) : [],
    guests: Number(guests),
    flightClass,
    costCenter: costCenterId,
    travelReason: travelReasonId,
    policyResolvedId,
  };
}

export function initPassengerForm(query) {
  return {
    type: actionTypes.CBT_INIT_FROM_URL,
    passengersForm: cbtPassengersFormParamsFromQuery(query),
  };
}

export function incrementGuests() {
  return {
    type: actionTypes.CBT_INCREMENT_GUESTS,
  };
}

export function decrementGuests() {
  return {
    type: actionTypes.CBT_DECREMENT_GUESTS,
  };
}

export function changeFlightClass(flightClass) {
  return {
    type: actionTypes.CBT_CHANGE_FLIGHT_CLASS,
    flightClass,
  };
}

export function changeCostCenter(costCenter) {
  return {
    type: actionTypes.CBT_CHANGE_COST_CENTER,
    costCenter,
  };
}

export function changeTravelReason(travelReason) {
  return {
    type: actionTypes.CBT_CHANGE_TRAVEL_REASON,
    travelReason,
  };
}

export function addTraveller(traveller) {
  if (!traveller) {
    return { type: actionTypes.NULL_ACTION };
  }

  return {
    type: actionTypes.CBT_ADD_TRAVELLER,
    traveller,
  };
}

export function removeTraveller(traveller) {
  return {
    type: actionTypes.CBT_REMOVE_TRAVELLER,
    traveller,
  };
}

export function setPolicyResolvedId(id) {
  return {
    type: actionTypes.SET_CBT_POLICY_RESOLVED_ID,
    data: { name: "policyResolvedId", value: id },
  };
}

export function clearErrors() {
  return {
    type: actionTypes.CBT_PASSENGER_FORM_CLEAR_ERRORS,
  };
}

export function closeForm() {
  return {
    type: actionTypes.CBT_CLOSE_PASSENGERS_FORM,
  };
}

export const setCbtValue = (name, value) => {
  return {
    type: actionTypes.SET_CBT_VALUE,
    data: { name, value },
  };
};
